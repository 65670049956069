import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import Layout from "../components/Layout";
import Menu from "../components/Menu";
import LinkWorkFrom from "../components/Link/LinkWorkFrom";

const WorkTemplate = ({ pageContext, location }) => {
  const { data: templateData } = pageContext;
  return (
    <Layout
      seoTitle={templateData.meta_title || templateData.title || "Work"}
      seoDescription={templateData.meta_description}
      location={location}
    >
      <div className="pt-hh px-ogs pb-ogs">
        <div className="relative flex flex-wrap -ml-gs">
          <div className="w-full lg:w-2/3 order-last lg:order-first pl-gs">
            <div className="js-transition-link-target">
              <div className="flex flex-wrap -ml-gs -mt-gs">
                {templateData.work
                  .filter(item => item.work_item.document)
                  .map((item, index) => {
                    const { data, uid } = item.work_item.document;
                    const thumbnail = item.work_item_thumbnail.fluid
                      ? item.work_item_thumbnail.fluid
                      : data.hero_image.fluid;
                    if (thumbnail) {
                      return (
                        <div key={index} className="w-1/2 pl-gs pt-gs">
                          <LinkWorkFrom
                            className="block relative group"
                            to={`/work/${uid}/`}
                          >
                            <Image
                              placeholderStyle={{ display: "none" }}
                              fluid={{
                                ...thumbnail,
                                aspectRatio: 0.666
                              }}
                              loading="eager"
                            />
                            {data.title && (
                              <>
                                <div className="absolute inset-x-0 bottom-0 h-1/4 transition-opacity duration-500 opacity-100 md:opacity-0 group-hover:opacity-100 bg-gradient" />
                                <div className="absolute bottom-0 inset-x-0 p-6">
                                  <div className="transition-all duration-700 opacity-100 md:opacity-0 transform translate-y-0 md:translate-y-1/2 group-hover:opacity-100 group-hover:translate-y-0">
                                    <h3 className="text-base md:text-lg lg:text-xl text-white leading-tight">
                                      {data.title}
                                    </h3>
                                  </div>
                                </div>
                              </>
                            )}
                          </LinkWorkFrom>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
              {templateData.body.html && (
                <div className="w-full pt-ogs">
                  <div
                    className="text-base md:text-lg lg:text-xl leading-relaxed space-y-4 lg:space-y-6"
                    dangerouslySetInnerHTML={{
                      __html: templateData.body.html
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-full lg:w-1/3 order-first lg:order-last pl-gs">
            <div className="lg:sticky lg:top-hh">
              <Menu isWorkPage location={location} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

WorkTemplate.propTypes = {
  location: PropTypes.object.isRequired
};

export default WorkTemplate;
